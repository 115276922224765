html * {
  color: #000 !important;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

main {
  height: 100vh;
}

#profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}


#portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#me img {
  position: relative;
  border-radius: 50%;
  z-index: -20;
}


#role {
  margin-top: 1em;
  font-weight: bold;
  font-family: "Source Code Pro", monospace;
  color: #fff;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

#role span {
  content: "";
  width: 10px;
  height: 20px;
  margin-left: -5px;
  margin-bottom: -2px;
  background: black;
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
}

#role a {
  width: 30px;
}

#social-container {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
}

#selector {
  position: fixed;
  top: 0;
  z-index: 2;
}

#social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

#social img {
  width: 30px;
  margin: 0.5em;
}

#canvas-container {
  position: fixed;
  top: 0px;
  z-index: -10;
  right: 0;
  bottom: 0;
  left: 0;
}

.margin-top {
  margin-top: 2rem;
}

@media (max-width: 1250px) {
  #me img {
    width: 300px;
  }

  #role {
    font-size: 3em;
  }

  #role span {
    width: 35px;
    height: 60px;
    margin-left: -5px;
    margin-bottom: -10px;
  }

  #social img {
    width: 80px;
    margin-bottom: 2em;
  }

  #portfolio {
    font-size: 38px;
  }
}
