html * {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: #000 !important;
}

main {
  height: 100vh;
}

#profile {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  display: flex;
}

#portfolio {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#me img {
  z-index: -20;
  border-radius: 50%;
  position: relative;
}

#role {
  color: #fff;
  margin-top: 1em;
  font-family: Source Code Pro, monospace;
  font-weight: bold;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

#role span {
  content: "";
  background: #000;
  width: 10px;
  height: 20px;
  margin-bottom: -2px;
  margin-left: -5px;
  animation: 1.5s steps(2, end) infinite cursor-blink;
  display: inline-block;
}

#role a {
  width: 30px;
}

#social-container {
  z-index: 1;
  width: 100%;
  position: fixed;
  bottom: 0;
}

#selector {
  z-index: 2;
  position: fixed;
  top: 0;
}

#social {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  display: flex;
}

#social img {
  width: 30px;
  margin: .5em;
}

#canvas-container {
  z-index: -10;
  position: fixed;
  inset: 0;
}

.margin-top {
  margin-top: 2rem;
}

@media (width <= 1250px) {
  #me img {
    width: 300px;
  }

  #role {
    font-size: 3em;
  }

  #role span {
    width: 35px;
    height: 60px;
    margin-bottom: -10px;
    margin-left: -5px;
  }

  #social img {
    width: 80px;
    margin-bottom: 2em;
  }

  #portfolio {
    font-size: 38px;
  }
}
/*# sourceMappingURL=index.bcbb8950.css.map */
